<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="(edit = true), (add = false)"
        >編集</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <template>
        <v-text-field
          v-model="id"
          label="担当ID"
          prepend-icon="mdi-account"
          outlined
          readonly
        ></v-text-field>
        <v-text-field
          v-model="tanNo"
          label="大臣コード"
          prepend-icon="mdi-account"
          outlined
          readonly
        ></v-text-field>
        <v-text-field
          v-model="tanNm"
          label="大臣名"
          prepend-icon="mdi-account"
          outlined
          readonly
        ></v-text-field>
        <v-text-field
          v-model="dispTanNm"
          label="担当名"
          prepend-icon="mdi-account"
          outlined
          :rules="[Rules.Required, tanniNameRule]"
        ></v-text-field>
      </template>
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";

const SelectsDelete = {
  Delete: 0,
  Default: 1
};

export default {
  name: "TantoDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {},
  data() {
    return {
      args: "",
      params: null,
      add: false,
      edit: false,
      icode: "",
      name: "",
      tanNo: "",
      tanNm: "",
      dispTanNm: "",
      onflg: true,
      selectDelete: SelectsDelete.Default,
      SelectsDelete,
      tanniNameRule: value => {
        const errorValue = "担当名を２０文字以内で入力してください。";
        return value.length <= 20 || errorValue;
      }
    };
  },
  computed: {
    Title() {
      return (
        this.params.title +
        " " +
        (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit", this.id);

      this.$loading();

      try {
        const param = {
          id: this.id,
          ckbn: this.ckbn,
          tanNo: this.tanNo,
          tanNm: this.tanNm,
          kanaNm: this.kanaNm,
          bunrui: this.bunrui,
          dispTanNm: this.dispTanNm
        };

        console.log("submit_param", param);
        if (this.add) {
          await this.$post(this.Paths.tanto, param);
        } else {
          await this.$put(this.Paths.tanto, param);
        }
        this.$info("更新しました。", "担当マスタ");
        this.edit = false;
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    back() {
      const path = "/list/tanto";
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    }
  },
  async created() {
    console.log(this.name, "created");
    const route = this.$route.params;
    const args = route.args;
    this.params = route;
    this.args = route.args;
    console.log("filter", this.params.filter);

    this.$loading();
    try {
      if (this.params.prosess && typeof this.params.prosess === "string")
        this.params.prosess = this.params.prosess.toLowerCase();

      switch (this.params.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      if (!this.add) {
        this.id = args.id;
        this.tanNo = args.tanNo;
        this.tanNm = args.tanNm;
        this.dispTanNm = args.dispTanNm;
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style></style>
